::-webkit-scrollbar,
::-webkit-scrollbar-vertical {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #82c6c4;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #82c6c4;
}
.p-datatable-scrollable .p-datatable-thead > tr > th {
  background-color: #00b0ab;
  font-size: 13px;
  text-align: left;
  /* padding: 0rem 0.6rem; */
}
.p-datatable .p-datatable-tbody > tr > td {
  font-size: 13px;
   padding: 0rem 1rem !important;
  align-items: center;
  height: 50px;
}
.no-close-button .p-dialog-header-icon {
  display: none;
}

.custom-bg {
  background-color: #00b0ab;
  border: none !important;
  outline: none !important;
}
.p-dialog-footer button.custom-btn-red {
  background-color: #f44336 !important;
  border: none !important;
  outline: none !important;
  border-radius: 7px;
  color: #fff;
}

.custom-btn {
  background-color: #00b0ab !important;
  border: none !important;
  outline: none !important;
  border-radius: 7px;
  color: #fff;
}
.p-button {
  background-color: #00b0ab !important;
  border: none !important;
  outline: none !important;
  border-radius: 7px;
  color: #fff;
}
.p-button {
  background-color: #00b0ab !important;
  border: none !important;
  outline: none !important;
  border-radius: 7px;
  color: #fff;
}
.custom-btn:hover {
  background-color: #00b0ab !important;
  border: none !important;
  outline: none !important;
  color: #fff;
}
.table-custom {
  box-shadow: 0px 8px 5px -3px #ecf0f1 !important;
  border-radius: 7px;
}
.top-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.p-rating .p-rating-icon.pi-star-fill {
  color: gold;
}
.heading-custom {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 20px 0px 20px;
  background-color: #00b0ab !important;
}
.black-custom {
  color: #232d42 !important;
}
.tile-custom {
  background-color: var(--surface-card);
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  /* background-color: #c6e6e5; */
  box-shadow: 0px 8px 5px 1px #ecf0f1 !important;
}
.slide-custom {
  background-color: var(--surface-card);
  /* padding: 1.5rem; */
  /* margin-bottom: 1rem; */
  border-radius: 10px;
  /* background-color: #c6e6e5; */
  box-shadow: 0px 8px 5px 1px #ecf0f1 !important;
}
/* .p-datatable-row-expansion{
    border: 8px solid #CCEFEE;

} */
.pointer {
  cursor: pointer;
}
.p-datatable .p-datatable-tbody > tr > td {
  /* padding: 0px !important; */
}
.p-menu.p-menu-overlay {
  z-index: 1001;
  min-width: 200px !important;
  transform-origin: center bottom;
  top: 0px;
  left: 1305.61px !important;
}
.custom-sub {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding: 20px;
}
.light-purple {
  background-color: #e4e2ff;
}
.light-orange {
  background-color: #fff0c4;
}
.light-green {
  background-color: #cff7ea;
}
.light-red {
  background-color: #fbd9d3;
}
.light-blue {
  background-color: #9fcbee;
}
.light-mustud {
  background-color: #fedd70;
}
.light-mehroon {
  background-color: #e3c8cd;
}
.multiple-images .upload-multiple,
.multiple-images .p-fileupload-choose {
  border: 2px dashed #00b0ab !important;
}
.text-white .p-button-label:hover {
  color: white !important;
}
.custom-white {
  color: #00b0ab !important;
  border: 1px solid #00b0ab !important;
  background-color: white !important;
}
.custom-white-red {
  color: #f44336 !important;
  border: 1px solid #f44336 !important;
  background-color: white !important;
}
.p-paginator {
  border: none !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: #000000 !important;
  background: #cedbe9 !important;
  border-color: #cedbe9 !important;
}
.p-menubar {
  background: transparent !important;
  border: none !important;
}
.custom-bg:hover {
  background-color: #00b0ab !important;
  border: none !important;
  outline: none !important;
  color: #fff !important;
}
.center-buttons .p-dialog-footer {
  display: flex;
  justify-content: center;
  padding-top: 18px;
  padding-bottom: 45px;
}
.p-confirm-dialog .p-dialog-header {
  text-align: center;
}
.bg-reddd {
  background-color: #f44336;
  padding: 20px;
  border-radius: 50%;
  color: white;
}
/* .p-dialog .p-dialog-header{
    border-bottom: 1px solid #00b0ab ;
} */
.header-center-align {
  text-align: center !important;
}
.padding {
  text-align: center !important;
  min-width: 16rem;
}
.cross-button {
  background-color: transparent !important;
  color: red !important;
  height: 0px;
}
.color-gray {
  color: #707a8a;
}
.red {
  color: red;
  background-color: rgb(255, 229, 229);
  padding: 14px;
  border-radius: 3px;
}
.product-button {
  color: #00b0ab !important;
  cursor: pointer !important;
  background: none !important;
  border: none !important;
  /* padding: 0.75rem 1.25rem; */
  font-size: 1rem !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
}
.product-button:hover {
  color: #00b0ab !important;
  cursor: pointer;
  background: none !important;
  border: none;
  /* padding: 0.75rem 1.25rem; */
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
}
.custom-bg:focus {
  border: none !important;
  box-shadow: none !important;
}
.w100 {
  width: 100%;
}
.custom-btn-delete {
  background-color: white !important;
  color: red !important;
  border: none;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(211, 194, 194, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.loader-container .logo {
  animation: zoomInOut 2s infinite alternate;
  width: 100px;
  height: auto;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.error {
  color: red !important;
  margin: 5px 0px 0px 5px;
  font-size: 9px;
}

.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  box-shadow: none !important;
}
.custom-btn-edit {
  background-color: white !important;
  color: #7dc6f6 !important;
  border: none;
}
.clr_custom {
  color: #fff;
}
.p-dialog-content {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.custom-btn-delete:focus {
  box-shadow: none !important;
}
.wide-header {
  width: 30% !important;
}
.custom-btn-edit:focus {
  box-shadow: none !important;
}
.p-20 {
  margin: 20px;
}
.slick-prev:before,
.slick-next:before {
  color: black;
}
.slick-prev {
  left: -20px !important;
}

.p-datatable-scrollable .p-datatable-wrapper {
  border-radius: 8px;
}
.auth_main {
  width: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 100vh !important;
}
.slick-next {
  right: -20px;
}
.blue {
  background-color: #00b0ab;
  color: white;
  width: 80% !important;
  margin: 0 auto;
}
.bg_custom {
  background-color: #00b0ab;
}

.mid {
  flex-direction: column;
  justify-content: center;
  display: flex;
}
.booked {
  background-color: #b88a00;
  color: white;
}
.unbooked {
  background-color: #00722a;
  color: white;
}
.left-border {
  border-top-left-radius: 8px;
}
.right-border {
  border-top-right-radius: 8px;
}
.slider-item {
  text-align: center;
}
.p-datatable table {
  width: 100%;
}
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  display: none;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #00b0ab !important;
}
.p-paginator {
  align-items: right !important;
  justify-content: right !important;
}
.p-datatable .p-datatable-thead > tr > th {
  background-color: #edf2f7 !important;
}
.Product-Card {
  padding: 10px;
  margin: 0px 10px;
  border-radius: 7px;
  border: 1px solid #e6edf3;
}
.round {
  width: 30px;
  height: 30px;
  border: 1px solid #00b0ab;
  border-radius: 50%;
  padding: 7px;
}
.border {
  border: 8px solid #edf2f7;
}
.color_yellow {
  background-color: #b88a00;
  padding: 8px;
  height: 20px;
  border: none;
}
.color_blue {
  background-color: #00722a;
  padding: 8px;
  height: 20px;
  border: none;
}
.p-datatable .p-datatable-thead > tr > th {
  font-weight: 500 !important;
}
.w80 {
  width: 80%;
  float: right;
}
.w70 {
  width: 70%;
  float: right;
}
.custom-color {
  color: #00b0ab !important;
}
a {
  cursor: pointer;
}
.p-card-header {
  padding: 10px;
}
.multiple-images .upload-multiple,
.multiple-images .p-fileupload-choose {
  height: 150px;
  overflow-y: scroll;
  border: 2px dashed #7c251f;
  border: 2px dashed var(--primary-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
  padding: 15px;
}
.multiple-images .image-preview img {
  width: 50px;
}
.p-dialog-content {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: #00b0ab;
  background: #c6e6e5;
  border-color: #c6e6e5;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #00b0ab;
  border-color: #00b0ab;
  box-shadow: none;
}
.card-set {
  padding: 1.5rem 1.5rem 0px 1.5rem !important;
  margin-bottom: 0.5rem !important;
}
.card.booked:hover {
  cursor: pointer;
}
.card.unbooked {
  cursor: pointer;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #00b0ab;
  box-shadow: none;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.okkkkkk.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: red !important;
}
/* .p-tabview .p-tabview-panels {
  padding: 1.25rem 0px;
} */
.Staric-Custom {
  color: red;
}
.p-inputtext:enabled:focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}
.p-inputtext:enabled:hover {
  border: 1px solid #ced4da;
  box-shadow: none;
}
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.message {
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 8px;
  max-width: 70%;
}

.message.sent {
  background-color: #dcf8c6;
  align-self: flex-end;
}

.message.received {
  background-color: #f0f0f0;
  align-self: flex-start;
}

.chat-input {
  display: flex;
  padding: 10px;
  background-color: #f0f0f0;
}

.chat-input input {
  flex: 1;
  padding: 8px;
  border-radius: 20px;
  border: none;
  margin-right: 10px;
}

.chat-input button {
  background-color: #128c7e;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
}

.faqCustomAccordion .p-accordion-header-text {
  display: flex;
  align-items: center;
  width: 100%;
}
.p-accordion .p-accordion-header .p-accordion-header-link{
    padding: .5rem 1.25rem;
    height: 55px !important;
}
.overflowX{
  overflow-x: auto;
  width: 100%;

}
.p-tabview .p-tabview-nav li {
  list-style: none;
}
.hidden-big{
  display: none !important;
}
@media only screen and (max-width: 999px){
  .hidden-big{
    display: block !important;
  }
}


@media only screen and (max-width: 768px){
  .hidden-small{
    display: none !important;
  }}




  .product-carousel {
    width: 100%;
}

.product-card {
    display: flex;
    flex-direction: column;
    margin: 0 5px; /* Adjust margins to your preference */
    overflow: hidden;
}

.product-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-image {
    width: 100%;
    height: 160px; /* Fixed height */
    object-fit: cover; /* Ensures image covers the space without distortion */
}

.product-title {
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
}

.product-description {
    text-align: center;
}

.p-inputswitch.p-highlight .p-inputswitch-slider {
  background: #06b6d4;
}